@font-face {
    font-family: "Yu Gothic";
    src: local("Yu Gothic Medium");
    font-weight: 100;
}

@font-face {
    font-family: "Yu Gothic";
    src: local("Yu Gothic Medium");
    font-weight: 200;
}

@font-face {
    font-family: "Yu Gothic";
    src: local("Yu Gothic Medium");
    font-weight: 300;
}

@font-face {
    font-family: "Yu Gothic";
    src: local("Yu Gothic Medium");
    font-weight: 400;
}

@font-face {
    font-family: "Yu Gothic";
    src: local("Yu Gothic Bold");
    font-weight: bold;
}

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue Regular");
    font-weight: 100;
}

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue Regular");
    font-weight: 200;
}
body{
    padding-top: 60px;
}
body,
tooltip,
popover {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;
}

@media all and (-ms-high-contrast: none) {
    body,
    tooltip,
    popover {
        font-family: Verdana, Meiryo, sans-serif;
    }
}

.btn-primary, .btn-secondary, badge-primary, btn-outline-danger {
    border-radius:0;
}
.btn-primary {
    background: #724000;
    border-color: #724000;
    color: #fff;
}
.badge-primary:hover, .badge-primary:focus,
.btn-primary:hover, .btn-primary:focus,
.btn-primary:active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary:active.focus,
.btn-primary.active, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary,
.open > .dropdown-toggle.btn-primary:hover,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary.focus,
.btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus {
    background: #8c5a00;
    border-color: #8c5a00;
    color: #fff;
}
.btn-primary.outline {
    border: 2px solid #724000;
    color: #fff;
}

.btn-primary:focus, .btn-primary.focus {
    background: #8c5a00;
    border-color: #724000;
    box-shadow: 0 0 0 0.2rem rgba(114, 64, 0, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
    background: #bba788;
    border-color: #bba788;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    background: #8c5a00;
    border-color: #724000;
    box-shadow: 0 0 0 0.2rem rgba(114, 64, 0, 0.5);
}
.badge-primary  {
    background: #8c5a00;
    border-color: #724000;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}
.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
}

/* album */
:root {
    --jumbotron-padding-y: 3rem;
}

.jumbotron {
    padding-top: var(--jumbotron-padding-y);
    padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: #fff;
}
@media (min-width: 768px) {
    .jumbotron {
        padding-top: calc(var(--jumbotron-padding-y) * 2);
        padding-bottom: calc(var(--jumbotron-padding-y) * 2);
    }
}

.jumbotron p:last-child {
    margin-bottom: 0;
}

.jumbotron-heading {
    font-weight: 300;
}

.jumbotron .container {
    max-width: 40rem;
}

footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

footer p {
    margin-bottom: .25rem;
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

/* カテゴリボタン */
/* スマホ/タブレット対応 */
@media (max-width: 1200px) {
    .btn-category {
        position:fixed;
        z-index:10;
        top: 60px;
    }
    .cards-base {
        margin-top:60px;
    }
    .btn-up {
        position:fixed;
        z-index:10;
        right:15px;
        bottom:10px;
    }
    .btn-pricelist {
        position:fixed;
        z-index:10;
        right:15px;
        top:70px;
    }
}